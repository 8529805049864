@import url(https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;500&display=swap);
footer {
  color: #7c7c7c;
  font-family: 'Roboto', 'Open Sans', 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  font-size: 16px;
  background-color: #ffffff;
  margin: 0;
  padding: 50px 0 0;
  display: flex;
  width: 100%;
  flex-direction: column;

}

footer .content {
  color: #7c7c7c;
  font-family: 'Roboto', 'Open Sans', 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  font-size: 16px;
  margin: 0 auto;
  z-index: 2;
  overflow: auto;
  width: 95%;
  max-width: 1141px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: inherit; 
}

.ocihometograils {
  color: #7c7c7c;
  background-color: #ffffff;
  font-family: 'Roboto', 'Open Sans', 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  font-size: 16px;
  margin-top: 0;
  margin-left: 0;
  margin-bottom: 30px;
  float: left;
  text-align: left !important;
}

.ocihometograils span {
  margin-top: 22px;
  text-align: left;
}

footer .socialmedianav {
  color: #7c7c7c;
  background-color: #ffffff;
  font-family: 'Roboto', 'Open Sans', 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  font-size: 16px;
  overflow: auto;
  text-align: center;
  margin-bottom: 30px;
  float: left;
}

footer .parnersnav {
  color: #7c7c7c;
  font-family: 'Roboto', 'Open Sans', 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  font-size: 16px;
  margin-right: 0;
  text-align: center;
  margin-bottom: 30px;
}

footer .links {
  line-height: 32px;
  text-align: right;
}

footer span {
  color: #7c7c7c;
  background-color: #ffffff;
  font-family: 'Roboto', 'Open Sans', 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  text-align: center;
  display: block;
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 15px;
} 

footer a {
  font-family: 'Roboto', 'Open Sans', 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  font-size: 16px;
  text-align: center !important;
  text-decoration: underline;
  color: #255aa8;
  margin-bottom: 0;
  margin-right: 0;
}

footer nav {
  background-color: #ffffff;
}


.copy-to-clipboard {
  position: relative;
  display: flex;
}

.copy-to-clipboard .copied {
  position: absolute;
  left: -5em;
  background: rgb(48, 48, 48);
  color: white;
  padding: 2px 4px;
  border-radius: 2px;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.copy-to-clipboard .copied.active {
  opacity: 1;
}

.MuiTooltip-popper {
  z-index: 9999 !important;
}

body.dark .MuiTooltip-arrow {
  color: var(--theme-light);
}
body.dark .MuiTooltip-tooltip {
  background-color: var(--theme-light);
  color: var(--theme-dark);
}

body.light .MuiTooltip-arrow {
  color: var(--theme-dark);
}
body.light .MuiTooltip-tooltip {
  background-color: var(--theme-dark);
  color: var(--theme-light);
}

.codePreview {
  min-height: 100%;
  margin: 0 !important;
  padding: 0 !important;
  overflow: unset !important;
  min-width: -webkit-max-content;
  min-width: max-content;
}

.code-preview.footer-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.code-preview.footer-wrapper div {
  opacity: 1;
  transition: 0.4s all;
}

.code-preview.footer-wrapper div.hidden {
  opacity: 0;
}

.lineNumbers {
  margin-right: 10px;
  padding-right: 5px !important;
  border-right: 1px dashed;
  height: 100%;
  min-width: 24px;
}

.MuiSnackbar-root.error-view {
  z-index: 3000;
}

.clipboard {
  margin-top: 1em;
  display: flex;
  align-items: center;
}

.clipboard:hover {
  text-decoration: underline;
  cursor: pointer;
}

.MuiSnackbar-root .MuiAlert-filledError {
  background-color: var(--theme-danger);
  color: white;
}

.MuiAlert-message {
  font-size: 1.1em;
}

.MuiSnackbar-root .MuiAlert-standardError .MuiAlert-message {
  display: flex;
  align-items: center;
}

.MuiSnackbar-root.error-view.MuiSnackbar-anchorOriginTopCenter {
  top: 24px;
  left: 50%;
  right: auto;
  width: 70%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.MuiSnackbar-root.error-view .MuiAlert-standardError .MuiAlert-icon {
  color: white;
}

.MuiPaper-root.MuiAlert-root {
  width: 100%;
}

/* Media */
@media screen and (max-width: 768px) {
  .MuiSnackbar-root.error-view.MuiSnackbar-anchorOriginTopCenter {
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

/* Modal */
.mn-feature-modal .modal-content h4 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  padding: 0px 0.8em;
  padding-top: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.mn-feature-modal .modal-content {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  height: calc(100% - 130px);
  margin-top: 74px;
  margin-bottom: 56px;
  padding-bottom: 56px;
}

.mn-feature-modal .modal-content h4 .helper-text {
  display: none;
}

.mn-feature-modal .input-field > label:not(.label-icon).active {
  font-size: 0.8em;
  left: 0;
}
/* Modal Cards */
.mn-feature-container .card {
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}

.mn-feature-container .card .card-content p {
  word-break: break-word;
}

.mn-feature-container .card .card-title {
  font-size: 1.2em;
}

/* Theming */
body.dark .mn-feature-modal .modal-content,
body.dark .mn-feature-modal .modal-content h4 {
  background-color: var(--theme-dark) !important;
}

body.light .mn-feature-modal .modal-content,
body.light .mn-feature-modal .modal-content h4 {
  background-color: var(--theme-light) !important;
}

body.dark .mn-feature-selection {
  background-color: var(--theme-dark-card-bg);
}

body.dark .mn-feature-selection:hover {
  background-color: var(--theme-dark-card-hover-bg) !important;
}

body.dark .mn-feature-selection.selected {
  background: var(--theme-dark-secondary) !important;
}
body.light .mn-feature-selection.selected {
  background: lightgrey !important;
}

body.dark .mn-feature-container .selected-item-row .title,
body.dark .mn-feature-container .card .card-title {
  color: white !important;
}

body.light .mn-feature-container .selected-item-row .title,
body.light .mn-feature-container .card .card-title {
  color: black !important;
}

/* Media */
@media screen and (max-width: 600px) {
  .modal.mn-feature-modal {
    height: 100% !important;
    max-height: 100% !important;
    width: 100% !important;
    max-width: 100% !important;
    top: 0% !important;
    font-size: 14px;
  }
}

.other-commands {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
}

.mn-header .logo-wrapper {
  background-color: #255AA8;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 220px;
  min-width: 100%;
  padding-bottom: 13px;
  --theme-dark: white;
}

.mn-logo .micronaut-launch-logo {
  padding-bottom: 100px;
}


.modal.modal-lg.next-steps {
  max-height: 90%;
}

.next-steps .title {
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.next-steps p.info {
  max-width: 600px;
  text-align: left;
}

.next-steps-wrapper {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.next-steps-code .heading,
.next-steps-wrapper .heading {
  margin-bottom: 0.6rem;
}

.next-steps-code,
.next-steps-row {
  display: flex;
  align-items: center;
  border: solid 1px rgba(0, 0, 0, 0.3);
}

.next-steps-row {
  padding: 6px;
  height: 3rem;
  align-items: center;
  display: flex;
}

.next-steps-row .text {
  overflow: scroll;
  white-space: nowrap;
}

.next-steps-row.multi-line {
  height: auto;
}

.next-steps-row.multi-line .text {
  white-space: initial;
}

.os-select-opt-row {
  display: flex;
}

.os-select-opt-col {
}

span.os-select {
  cursor: pointer;
  position: relative;
  margin-right: 1rem;
  letter-spacing: 1px;
  color: grey;
}

span.os-select::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: -3px;
  left: 0;
  visibility: hidden;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

span.os-select:hover::before {
  visibility: visible;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

.light span.os-select.active {
  color: black;
}
.light span.os-select::before {
  background-color: #000;
}
.dark span.os-select.active {
  color: white;
}
.dark span.os-select::before {
  background-color: white;
}

.next-steps-row .text {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.next-steps-row .icon {
  flex-shrink: 1;
  display: flex;
  align-items: center;
  height: 100%;
}

.next-steps-row .icon a {
  display: flex;
  align-items: center;
}

body.light .next-steps-row {
  color: black;
}
body.light .next-steps-row a {
  color: var(--theme-dark);
}

body.dark .next-steps-row {
  color: rgb(169, 183, 198);
  background: var(--theme-dark-card-bg);
}

body.dark .next-steps-row a {
  color: rgb(169, 183, 198);
}

.select-wrapper {
  width: 100%;
  margin-top: 3px;
}

.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
  padding-bottom: 12px !important;
}

.select-wrapper label + .MuiInput-formControl {
  margin-top: 19px;
}

.MuiFormControl-root {
  width: 100%;
}

.MuiFormLabel-root.MuiInputLabel-root {
  left: 0 !important;
  font-size: 1rem;
}

.MuiSelect-select:focus {
  background-color: transparent !important;
}

.MuiFormControl-root .MuiFormLabel-root.Mui-focused {
  color: grey;
}

.MuiInput-underline:after {
  border-bottom: 1px solid black !important;
}

body.light .MuiPaper-root.MuiMenu-paper.MuiPopover-paper {
  background-color: var(--theme-light);
  color: #000;
}

body.dark .MuiInput-underline:after {
  border-bottom: 1px solid white !important;
}

body.dark .MuiSelect-select:focus {
  background-color: transparent !important;
}

body.dark .MuiSvgIcon-root.MuiSelect-icon,
body.dark .MuiInputBase-root {
  color: white;
}

body.dark .MuiFormControl-root .MuiFormLabel-root {
  color: var(--label-grey);
}

body.dark .MuiFormControl-root .MuiFormLabel-root.Mui-focused {
  color: #fff;
}

body.dark .MuiPaper-root.MuiMenu-paper.MuiPopover-paper {
  background-color: #3a3a3a;
  color: #fff;
}

body.dark .MuiListItem-root:hover,
body.dark .MuiListItem-root.Mui-selected,
body.dark .MuiListItem-root.Mui-selected:hover {
  background-color: var(--theme-dark);
}

body.dark .MuiInput-underline,
body.dark .MuiInput-underline:after {
  border-bottom: 1px solid white !important;
}

/* .mn-starter-form-main .radio-group:focus-within label,
.mn-starter-form-main .input-field.col:focus-within label {
  color: orange !important;
}
.btn:focus {
  background-color: orange !important;
} */

.mn-starter-form-main .input-field .helper-text {
  min-height: 1px;
}

.mn-starter-form-main .input-field .mn-input {
  height: 2.7rem;
}

.mn-starter-form-main .input-field.col {
  padding: 0 0;
  width: 100%;
}

.mn-starter-form-main .input-field.col label {
  left: 0;
}

.mn-starter-form-main .mn-radio {
  margin-bottom: 5px;
}

.mn-starter-form-main .mn-radio .radio-row {
  position: relative;
}

.mn-starter-form-main .mn-radio label.input-field {
  display: block;
  margin-bottom: 5px;
}

/* Theme */
body.light .mn-starter-form-main .mn-radio .radio-label {
  color: black;
}

body.dark .mn-starter-form-main .mn-radio .radio-label {
  color: white;
}

/* Media */
@media screen and (max-width: 600px) {
  .mn-starter-form-main .mn-radio .radio-label {
    margin-right: 10px;
  }
  [type='radio']:not(:checked) + span,
  [type='radio']:checked + span {
    padding-left: 2em;
  }

  .mn-radio:last-of-type {
    margin-top: 10px;
  }
}

@media screen and (min-width: 600px) {
  .mn-starter-form-main .mn-radio .radio-label {
    display: block;
  }
  .mn-starter-form-main .mn-radio label.input-field {
    margin-top: 0;
  }
}

.mn-starter-form-main .mn-radio .loading-wrapper {
    width: 100%;
    height: 25px;
    padding: 4px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.2s ease all;
}

.mn-starter-form-main .mn-radio .loading .loading-wrapper {
    opacity: 1;
    visibility: visible;
}

.mn-starter-form-main .mn-radio .radio-label {
    opacity: 1;
}

.mn-starter-form-main .mn-radio .loading .radio-label {
    opacity: 0;
}

.mn-starter-form-main .mn-radio .loading .loading-wrapper .loading-ghost {
    opacity: 1;
    visibility: visible;
    -webkit-animation: loading-bg 1s linear 1s infinite alternate;
            animation: loading-bg 1s linear 1s infinite alternate;
}

.mn-starter-form-main .mn-radio .loading-wrapper .loading-ghost {
    background-color: rgba(136, 136, 136, 45%);
    width: 50%;
    height: 100%;
    border-radius: 9999px;
}

@-webkit-keyframes loading-bg {
    from {
        background-color: rgba(136, 136, 136, 65%);
    }
    to {
        background-color: rgba(136, 136, 136, 45%);
    }
}

@keyframes loading-bg {
    from {
        background-color: rgba(136, 136, 136, 65%);
    }
    to {
        background-color: rgba(136, 136, 136, 45%);
    }
}

.mn-starter-form-main
    .mn-radio
    .radio-row:nth-of-type(2)
    .loading-wrapper
    .loading-ghost {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
    width: 60%;
}
.mn-starter-form-main
    .mn-radio
    .radio-row:nth-of-type(3)
    .loading-wrapper
    .loading-ghost {
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
    width: 40%;
}

html {
  height: 100%;
  box-sizing: border-box;
  font-family: 'Fira Sans', sans-serif;
  font-size: 16px;
}

body {
  display: flex;
  position: relative;
  min-height: 100%;
  --theme-dark: black;
  --theme-dark-secondary: #3a3a3a;
  --theme-dark-card-bg: #161616;
  --theme-dark-card-hover-bg: #252525;
  --theme-light: #f5f5f5;
  --theme-light-secondary: #255AA8;
  --theme-danger: rgb(148, 16, 0);
  --label-grey: #9e9e9e;
  --label-darkgrey: #686868;
  --bundle-builder-trigger: black;
}

#root {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.mn-container {
  margin-top: 1rem;
}

.mn-main-container {
  flex: 1 1;
  background: linear-gradient(
    to bottom,
    var(--theme-light) 95%,
    rgba(242, 242, 242, 0)
  );
}

.mn-feature-container {
  flex: 0 1;
  padding-bottom: 50px;
  padding-left: 10px;
  padding-right: 10px;
}

.mn-footer-container {
  flex: 1 1;
  padding-bottom: 1em;
  padding-right: 12px;
}

.grid-container {
  padding-top: 48px;
  height: 100%;
}

.grid-column {
  height: 100%;
  overflow-y: auto;
  padding-left: 10px;
  padding-top: 10px;
  border-top: 1px solid;
}

[type='radio']:checked + span:after,
[type='radio'].with-gap:checked + span:before,
[type='radio'].with-gap:checked + span:after {
  border: 2px solid #000000;
}

.dark .mn-main-container {
  background: linear-gradient(
    to bottom,
    var(--theme-dark) 95%,
    rgba(45, 45, 45, 0)
  );
}

.btn {
  border-radius: 0;
}

.btn-floating i {
  color: inherit;
}

.grey-text {
  color: var(--label-grey) !important;
}

.light .grey-text {
  color: var(--label-darkgrey) !important;
}

.error-link {
  color: white !important;
  text-decoration: underline;
  font-weight: bolder;
}

.shortcut-legend ul li {
  font-size: 0.9rem;
}

label.MuiFormLabel-root,
.light label.input-field,
.input-field > label {
  color: var(--label-grey);
}

.light label.MuiFormLabel-root,
.light label.input-field,
.light .input-field > label {
  color: var(--label-darkgrey) !important;
}

.progress {
  background-color: #dfdfdf;
}

.progress .indeterminate {
  background-color: rgba(0, 0, 0, 0.74);
}

.progress-container {
  height: 8px;
  min-height: 8px;
}

.spinner-blue,
.spinner-blue-only {
  border-color: #000000;
}

.light {
  /* This is getting overloaded by our 'theme' but is also
   * part of materializecss, so we need to unset the font-weight
   */
  font-weight: normal;
}

/* Theme */
body.light {
  background-color: var(--theme-light);
  color: var(--theme-dark);
}

body.dark {
  background-color: var(--theme-dark);
  color: var(--theme-light);
}

/*Theme Buttons*/
body.dark .btn-flat {
  color: #fff;
}

body.light .btn-flat {
  color:var(--theme-light-secondary);
}

button {
  min-height: 3rem;
}
button.remove-button {
  min-height: unset;
}
button.light {
  background-color: var(--theme-light-secondary);
  color: #fff;
}

button.dark {
  background-color: var(--theme-light);
  color: #000;
}

button.light:hover,
button.light:focus {
  background-color: #255AA8FF;
}

button.dark:hover,
button.dark:focus {
  background-color: #fff;
}

/*Theme Radio*/
body.light [type='radio']:checked + span:after,
body.light [type='radio'].with-gap:checked + span:after {
  background-color: #255AA8FF;
}

body.dark [type='radio']:checked + span:after,
body.dark [type='radio'].with-gap:checked + span:after {
  background-color: #fff;
}

body.light [type='radio']:checked + span:after,
body.light [type='radio'].with-gap:checked + span:before,
body.light [type='radio'].with-gap:checked + span:after {
  border: 2px solid #255AA8FF;
}

body.dark [type='radio']:checked + span:after,
body.dark [type='radio'].with-gap:checked + span:before,
body.dark [type='radio'].with-gap:checked + span:after {
  border: 2px solid #fff;
}

/*Theme Input*/
body.light .mn-input:focus,
body.light .select-wrapper input.select-dropdown:focus {
  border-bottom: 1px solid #000000 !important;
  box-shadow: 0 1px 0 0 #000000 !important;
}

body.dark .mn-input:focus,
body.dark .select-wrapper input.select-dropdown:focus {
  border-bottom: 1px solid #fff !important;
  box-shadow: 0 1px 0 0 #fff !important;
}

body.dark .mn-input,
body.dark .select-wrapper input.select-dropdown {
  color: #fff;
}

body.light .mn-input:focus + label {
  color: #000 !important;
}

body.dark .mn-input:focus + label {
  color: #fff !important;
}

.pill {
  padding: 1px 8px;
  border-radius: 9999px;
}

body .dark.pill {
  color: #000 !important;
  background-color: white !important;
}

body .light.pill {
  color: white !important;
  background-color: black !important;
}

body.dark .mn-input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.25);
}

body.dark .mn-input::placeholder {
  color: rgba(255, 255, 255, 0.25);
}

body.dark .caret {
  fill: rgba(100, 100, 100, 0.87);
}

/*Theme Dropdown*/
body.light .dropdown-content li > a,
body.light .dropdown-content li > span {
  color: #000;
}

body.dark .dropdown-content li > a,
body.dark .dropdown-content li > span {
  color: #fff;
  background-color: #3a3a3a;
}

body.dark .dropdown-content li > a:hover,
body.dark .dropdown-content li > span:hover {
  background-color: var(--theme-dark);
}

body.light svg.mn-logo path,
body.light svg.micronaut-foundation path,
body.light svg.micronaut-foundation polygon {
  fill: var(--theme-dark);
}

body.light svg.inverse-theme path {
  fill: gray;
}
body.dark svg.inverse-theme path {
  fill: lightgray;
}

body.dark svg.mn-logo path,
body.dark svg.micronaut-foundation path,
body.dark svg.micronaut-foundation polygon {
  fill: var(--theme-light);
}

/*Theme Explicit*/
.bg-light {
  background-color: var(--theme-light) !important;
  color: var(--theme-dark) !important;
}
.bg-dark {
  background-color: var(--theme-dark) !important;
  color: var(--theme-light) !important;
}

/* Media */

@media only screen and (min-width: 601px) {
  .container {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  footer.container.mn-footer-container {
    padding-right: 0;
    padding-left: 0;
  }

  .mn-feature-container .selected-features,
  .mn-container .sticky {
    position: relative !important;
    top: 0;
    overflow-y: scroll;
  }
}

@media only screen and (min-width: 993px) {
  .container {
    width: 100%;
  }
}

@media only screen and (max-width: 400px) {
  .row .col.xs6 {
    width: 50%;
    margin-left: auto;
    left: auto;
    right: auto;
    padding-bottom: 5px;
  }
}

.button-row .action-button-icon {
    display: block;
}
/* Media */
@media only screen and (max-width: 788px) {
    .button-row .action-button-icon {
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .button-row .btn {
        font-size: 0.9em;
        padding: 0 10px;
    }
}

@media only screen and (max-width: 500px) {
    .button-row .btn {
        font-size: 0.7em;
        padding: 0 4px;
    }
}

@media only screen and (max-width: 400px) {
    .button-row .btn {
        font-size: 0.7em;
        padding: 0 10px;
    }
}

@media only screen and (max-width: 225px) {
    .button-row .btn {
        padding: 0 2.5em;
    }

    .button-row .btn {
        font-size: 0;
        margin: auto;
    }

    .button-row .btn i {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        font-size: 16px;
    }

    .button-row .action-button-icon {
        display: block;
    }
}

.d-flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}
.flex-col {
  flex-direction: column;
}
.flex {
  flex: 1 1;
}

.flex-grow {
  flex-grow: 1;
}
.flex-shrink {
  flex-shrink: 1;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  z-index: 2001;
}

.MuiPopover-root {
  z-index: 2002 !important;
}

.clickable {
  cursor: pointer;
}

.jcc {
  justify-content: center;
}

.aic {
  align-items: center;
}
.nowrap {
  white-space: nowrap;
}
.o-scroll {
  overflow: scroll;
}

.svg-white path {
  color: white;
}

.modal {
  width: 35%;
  min-width: 320px;
  z-index: 9998 !important;
}

.modal.preview h4,
.modal.diff h4 {
  padding-bottom: 10px;
  margin-bottom: 0;
  position: fixed;
}

.modal h4 {
  font-size: 1.5rem;
}

.modal h5 {
  font-size: 1.3rem;
}

.modal.modal-lg {
  width: 65%;
}

.modal.modal-xl {
  width: 90%;
}

.modal.mn-feature-modal,
.modal.diff,
.modal.preview {
  width: 75%;
  max-height: 90%;
  height: 90%;
}

.modal.dark,
.modal.dark .modal-footer {
  background-color: var(--theme-dark);
  border-top: 1px solid rgb(241 240 240 / 30%);
}

.modal.light,
.modal.light .modal-footer {
  background-color: var(--theme-light);
  border-top: 1px solid rgb(103 103 103 / 10%);
}

.dark .modal-overlay {
  background: rgb(38 38 38);
  opacity: 0.8 !important;
}

/* Modal Media */
@media only screen and (max-width: 800px) {
  .modal {
    width: 60%;
  }
}

@media only screen and (max-width: 600px) {
  .modal {
    height: 100% !important;
    max-height: 100% !important;
    width: 100% !important;
    max-width: 100% !important;
    top: 0% !important;
  }
}

/* We need a bit of a heavy hand on a number of elements that
 * are pre-built to Materialize Standards.
 */

.button-row button {
  text-transform: uppercase;
}

/* Label above the Text input */
.input-field > label:not(.label-icon).active {
  -webkit-transform: translateY(-14px) scale(1);
          transform: translateY(-14px) scale(1);
}

/* Label Select Options */
.MuiInputLabel-shrink {
  -webkit-transform: translate(0, 1.5px) scale(1) !important;
          transform: translate(0, 1.5px) scale(1) !important;
}

.button-row button,
.input-field,
.mn-input,
.MuiSelect-root.MuiInput-input,
.MuiListItem-button.Mui-selected,
.radio-group,
.radio-text {
  font-family: 'Roboto', sans-serif !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
}

.radio-group label,
.input-field label {
  font-family: 'Roboto', sans-serif !important;
  font-size: 0.75rem !important;
  font-weight: 400 !important;
}

.MuiPopover-root {
  z-index: 2002 !important;
}

.MuiSnackbar-root.error-view {
  z-index: 9999;
}

.modal-overlay {
  z-index: 2004 !important;
}

.modal {
  z-index: 9998 !important;
}

.sticky {
  z-index: 2001;
}

.mn-footer-container {
  z-index: 1001;
}

