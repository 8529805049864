/* Modal */
.mn-feature-modal .modal-content h4 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  padding: 0px 0.8em;
  padding-top: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.mn-feature-modal .modal-content {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  height: calc(100% - 130px);
  margin-top: 74px;
  margin-bottom: 56px;
  padding-bottom: 56px;
}

.mn-feature-modal .modal-content h4 .helper-text {
  display: none;
}

.mn-feature-modal .input-field > label:not(.label-icon).active {
  font-size: 0.8em;
  left: 0;
}
/* Modal Cards */
.mn-feature-container .card {
  cursor: pointer;
  user-select: none;
}

.mn-feature-container .card .card-content p {
  word-break: break-word;
}

.mn-feature-container .card .card-title {
  font-size: 1.2em;
}

/* Theming */
body.dark .mn-feature-modal .modal-content,
body.dark .mn-feature-modal .modal-content h4 {
  background-color: var(--theme-dark) !important;
}

body.light .mn-feature-modal .modal-content,
body.light .mn-feature-modal .modal-content h4 {
  background-color: var(--theme-light) !important;
}

body.dark .mn-feature-selection {
  background-color: var(--theme-dark-card-bg);
}

body.dark .mn-feature-selection:hover {
  background-color: var(--theme-dark-card-hover-bg) !important;
}

body.dark .mn-feature-selection.selected {
  background: var(--theme-dark-secondary) !important;
}
body.light .mn-feature-selection.selected {
  background: lightgrey !important;
}

body.dark .mn-feature-container .selected-item-row .title,
body.dark .mn-feature-container .card .card-title {
  color: white !important;
}

body.light .mn-feature-container .selected-item-row .title,
body.light .mn-feature-container .card .card-title {
  color: black !important;
}

/* Media */
@media screen and (max-width: 600px) {
  .modal.mn-feature-modal {
    height: 100% !important;
    max-height: 100% !important;
    width: 100% !important;
    max-width: 100% !important;
    top: 0% !important;
    font-size: 14px;
  }
}
