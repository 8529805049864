.mn-header .logo-wrapper {
  background-color: #255AA8;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 220px;
  min-width: 100%;
  padding-bottom: 13px;
  --theme-dark: white;
}

.mn-logo .micronaut-launch-logo {
  padding-bottom: 100px;
}

