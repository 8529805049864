footer {
  color: #7c7c7c;
  font-family: 'Roboto', 'Open Sans', 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  font-size: 16px;
  background-color: #ffffff;
  margin: 0;
  padding: 50px 0 0;
  display: flex;
  width: 100%;
  flex-direction: column;

}

footer .content {
  color: #7c7c7c;
  font-family: 'Roboto', 'Open Sans', 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  font-size: 16px;
  margin: 0 auto;
  z-index: 2;
  overflow: auto;
  width: 95%;
  max-width: 1141px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: inherit; 
}

.ocihometograils {
  color: #7c7c7c;
  background-color: #ffffff;
  font-family: 'Roboto', 'Open Sans', 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  font-size: 16px;
  margin-top: 0;
  margin-left: 0;
  margin-bottom: 30px;
  float: left;
  text-align: left !important;
}

.ocihometograils span {
  margin-top: 22px;
  text-align: left;
}

footer .socialmedianav {
  color: #7c7c7c;
  background-color: #ffffff;
  font-family: 'Roboto', 'Open Sans', 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  font-size: 16px;
  overflow: auto;
  text-align: center;
  margin-bottom: 30px;
  float: left;
}

footer .parnersnav {
  color: #7c7c7c;
  font-family: 'Roboto', 'Open Sans', 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  font-size: 16px;
  margin-right: 0;
  text-align: center;
  margin-bottom: 30px;
}

footer .links {
  line-height: 32px;
  text-align: right;
}

footer span {
  color: #7c7c7c;
  background-color: #ffffff;
  font-family: 'Roboto', 'Open Sans', 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  text-align: center;
  display: block;
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 15px;
} 

footer a {
  font-family: 'Roboto', 'Open Sans', 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  font-size: 16px;
  text-align: center !important;
  text-decoration: underline;
  color: #255aa8;
  margin-bottom: 0;
  margin-right: 0;
}

footer nav {
  background-color: #ffffff;
}

