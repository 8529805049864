.modal {
  width: 35%;
  min-width: 320px;
  z-index: 9998 !important;
}

.modal.preview h4,
.modal.diff h4 {
  padding-bottom: 10px;
  margin-bottom: 0;
  position: fixed;
}

.modal h4 {
  font-size: 1.5rem;
}

.modal h5 {
  font-size: 1.3rem;
}

.modal.modal-lg {
  width: 65%;
}

.modal.modal-xl {
  width: 90%;
}

.modal.mn-feature-modal,
.modal.diff,
.modal.preview {
  width: 75%;
  max-height: 90%;
  height: 90%;
}

.modal.dark,
.modal.dark .modal-footer {
  background-color: var(--theme-dark);
  border-top: 1px solid rgb(241 240 240 / 30%);
}

.modal.light,
.modal.light .modal-footer {
  background-color: var(--theme-light);
  border-top: 1px solid rgb(103 103 103 / 10%);
}

.dark .modal-overlay {
  background: rgb(38 38 38);
  opacity: 0.8 !important;
}

/* Modal Media */
@media only screen and (max-width: 800px) {
  .modal {
    width: 60%;
  }
}

@media only screen and (max-width: 600px) {
  .modal {
    height: 100% !important;
    max-height: 100% !important;
    width: 100% !important;
    max-width: 100% !important;
    top: 0% !important;
  }
}
