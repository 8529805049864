/* We need a bit of a heavy hand on a number of elements that
 * are pre-built to Materialize Standards.
 */

.button-row button {
  text-transform: uppercase;
}

/* Label above the Text input */
.input-field > label:not(.label-icon).active {
  transform: translateY(-14px) scale(1);
}

/* Label Select Options */
.MuiInputLabel-shrink {
  transform: translate(0, 1.5px) scale(1) !important;
}

.button-row button,
.input-field,
.mn-input,
.MuiSelect-root.MuiInput-input,
.MuiListItem-button.Mui-selected,
.radio-group,
.radio-text {
  font-family: 'Roboto', sans-serif !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
}

.radio-group label,
.input-field label {
  font-family: 'Roboto', sans-serif !important;
  font-size: 0.75rem !important;
  font-weight: 400 !important;
}
