.d-flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}
.flex-col {
  flex-direction: column;
}
.flex {
  flex: 1;
}

.flex-grow {
  flex-grow: 1;
}
.flex-shrink {
  flex-shrink: 1;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  z-index: 2001;
}

.MuiPopover-root {
  z-index: 2002 !important;
}

.clickable {
  cursor: pointer;
}

.jcc {
  justify-content: center;
}

.aic {
  align-items: center;
}
.nowrap {
  white-space: nowrap;
}
.o-scroll {
  overflow: scroll;
}

.svg-white path {
  color: white;
}
