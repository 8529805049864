.copy-to-clipboard {
  position: relative;
  display: flex;
}

.copy-to-clipboard .copied {
  position: absolute;
  left: -5em;
  background: rgb(48, 48, 48);
  color: white;
  padding: 2px 4px;
  border-radius: 2px;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.copy-to-clipboard .copied.active {
  opacity: 1;
}
