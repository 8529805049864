.MuiTooltip-popper {
  z-index: 9999 !important;
}

body.dark .MuiTooltip-arrow {
  color: var(--theme-light);
}
body.dark .MuiTooltip-tooltip {
  background-color: var(--theme-light);
  color: var(--theme-dark);
}

body.light .MuiTooltip-arrow {
  color: var(--theme-dark);
}
body.light .MuiTooltip-tooltip {
  background-color: var(--theme-dark);
  color: var(--theme-light);
}
