.codePreview {
  min-height: 100%;
  margin: 0 !important;
  padding: 0 !important;
  overflow: unset !important;
  min-width: max-content;
}

.code-preview.footer-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.code-preview.footer-wrapper div {
  opacity: 1;
  transition: 0.4s all;
}

.code-preview.footer-wrapper div.hidden {
  opacity: 0;
}

.lineNumbers {
  margin-right: 10px;
  padding-right: 5px !important;
  border-right: 1px dashed;
  height: 100%;
  min-width: 24px;
}
